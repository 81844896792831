import React from "react";

import { Layout } from "../../components/Layout";
import { PageSeo } from "../../components/Seo";
import { InviteProcessSection } from "../../components/InviteProcessSection";
import { TestimonialsSection } from "../../components/TestimonialsSection";
import { Container, Heading } from "../../components/Page";
import { Copy } from "../../components/Copy";
import { StaticImage } from "gatsby-plugin-image";

const landscapeImageProps = {
  width: 700,
  height: 500,
};

const landscapeCenteredImageProps = {
  width: 700,
  height: 500,
  transformOptions: {
    cropFocus: "center",
  },
};

const portraitImageProps = {
  height: 500,
  width: 400,
  transformOptions: {
    cropFocus: "center",
  },
};

const cardstockImageProps = {
  width: 400,
  height: 400,
  imgClassName: "rounded-full",
};

const FinishingTouchesPage = () => {
  return (
    <Layout>
      <PageSeo tabTitleSegments={["Finishing Touches"]} title="Finishing Touches" />
      <Container>
        <div className="mx-auto max-w-narrow">
          <Heading>Finishing Touches</Heading>
          <Copy>
            <p>
              You can adorn your wedding invitations with an array of extras and finishing touches.
              There are so many options, such as; vellum overlays and jackets, wax seals, envelope liners, belly bands, ribbons, foiling, tassels and more.
              You can even choose which cardstock you would prefer.
            </p>
            <h2>Cardstock</h2>
            <p>
              Kimberley prints her wedding station on only the highest premium cardstock.
              Each has a different finish which can enhance the design.
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-6">
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Tintoretto Gesso.jpg" />
                <p className="text-center">Tintoretto Gesso 300gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Fresco Gesso.jpg" />
                <p className="text-center">Fresco Gesso 300gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Nettuno.jpg" />
                <p className="text-center">Nettuno 280gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Acquerello.jpg" />
                <p className="text-center">Acquerello 280gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Brown Kraft.jpg" />
                <p className="text-center">Brown Kraft 300gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Seeded.jpg" />
                <p className="text-center">Seeded Paper 180gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Laid.jpg" />
                <p className="text-center">Laid 300gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Rives Shetland.jpg" />
                <p className="text-center">Rives Shetland 250gsm</p>
              </div>
              <div>
                <StaticImage {...cardstockImageProps} src="../../images/finishing-touches/cardstock/Cream.jpg" />
                <p className="text-center">Cream 400gsm</p>
              </div>
            </div>
            <h2>Printed Guest Names</h2>
            <p>
              Have your wedding invitations already addressed to your guests with printed guest names.
              This makes one less thing for you to do and they will seamlessly fit into the wedding invitation design.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Extras-01.jpg" />
            <h2>Foiling</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <p className="mt-0">
                  Add some luxury to your wedding stationery by adding some foiling.
                  Foiled lettering is the most common option, but if you choose to have a custom design, elements of this could be in foiling too.
                  You can have gold, silver, copper and rose.
                  There's also the option to have foiling on vellum.
                </p>
              </div>
              <div>
                <StaticImage {...portraitImageProps} src="../../images/finishing-touches/Extras-04.jpg" />
              </div>
            </div>
            <h2>Vellum</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <StaticImage {...portraitImageProps} src="../../images/finishing-touches/Extras-02.jpg" />
              </div>
              <div>
                <p className="mt-0">
                  Vellum is a great way to add layers and dimension to your wedding invitations or on the day stationery.
                  Vellum can be added as an overlay to your stationery or you could have a vellum jacket, which will hold your invitation together.
                </p>
              </div>
            </div>
            <h2>Wax Seals</h2>
            <p>
              Wax seals are timeless and a big trend in wedding stationery at present.
              There are a range of sigils for you to choose from (and we are always adding to the collection) or you can opt for a custom initial seal.
              We also offer a range of different coloured waxes.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Website Wax seals-2.jpg" />
            <h2>Envelopes</h2>
            <p>
              Your wedding invitation quote will include the price of plain white envelopes.
              For an added cost you have the choice from a range of different coloured envelopes, including white hammered and white laid.
              Choosing the right colour envelope really helps to tie in all of your wedding stationery.
            </p>
            <h2>Envelope Liners</h2>
            <p>
              An envelope liner allows your guests to get a feel for what's inside the invitation.
              It's a sneak peak at your special day.
              You can have a pattern, an illustration on the flap or a plain colour.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Extras-06.jpg" />
            <h2>Ribbons</h2>
            <p>
              There is a choice between silk and satin ribbon for your invitations.
              They can be tied to hold together your invitation.
              Silk is very soft and lightweight with frayed edges.
              Satin is thick and shiny with sealed edges.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Satin Ribbon.jpg" />
            <h2>Belly Bands</h2>
            <p>
              Another way to hold together your wedding invitations is to use a Belly Band.
              This is a strip of card folded around the invitation and sealed at the back.
              A Belly Bank can  have an illustrated pattern or include your initials.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Belly Bands.jpg" />
            <h2>Stickers</h2>
            <p>
              Stickers are very versatile.
              They are great for sealing an envelope or Belly Band.
              They can also be used for your on the day stationery.
            </p>
            <StaticImage {...landscapeImageProps} src="../../images/finishing-touches/watercolour fade on the day set-61.jpg" />
            <h2>Tassels</h2>
            <p>
              If you want extra luxurious wedding invitations, then a tassel will give you this.
              Choose a tassel in a colour to suit your wedding theme and your illustrated wedding stationery and your guests will be amazed.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Tassels.jpg" />
            <h2>Illustrated Venue Map</h2>
            <p>
              A lot of venues are tucked away in the countryside with a hard to reach address which the sat nav just can't find.
              This can be worrying as you want your guests to easily arrive at your wedding.
              An illustrated map which matches your wedding stationery design is the perfect addition to your invitations.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Extras-08.jpg" />
            <h2>Calligraphy Addressed Envelopes</h2>
            <p>
              In the KGH Illustrates signature style modern calligraphy you can have your addressed hand written for you.
            </p>
            <StaticImage {...landscapeCenteredImageProps} src="../../images/finishing-touches/Extras-07.jpg" />
          </Copy>
        </div>
        <InviteProcessSection />
        <TestimonialsSection />
      </Container>
    </Layout>
  );
};

export default FinishingTouchesPage;
